import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/koenigsschiessen/2011/01.jpg"
import Image02 from "../../../images/koenigsschiessen/2011/02.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Koenigsschiessen2011 = props => {
  const ratings = [
    "Luftgewehr: Einzel Schützenklasse: Heiko Lippert (381 Ringe, 1. Platz)",
    "Luftgewehr: Einzel Damenklasse: Marina Haas (360 Ringe, 3. Platz)",
    "Luftgewehr: Mannschaft Schützenklasse: Heiko Lippert, Lars Papenfuß und Michael Rheinfrank (1096 Ringe, 1. Platz)",
    "Perkussionsgewehr: Einzel Altersklasse: Walter Beppler-Alt (134 Ringe, 1. Platz)",
    "Perkussionsgewehr: Einzel Schützenklasse: Heiko Lippert (127 Ringe, 3. Platz)",
    "KK-Sportgewehr: Einzel Schützenklasse: Heiko Lippert (272 Ringe, 2. Platz)",
    "KK-Sportgewehr: Mannschaft Schützenklasse: Heiko Lippert, Michael Rheinfrank, Rigo Schubach (771 Ringe, 1. Platz)",
  ]

  return (
    <Layout>
      <SEO title="Königsschießen 2011" lang="de" />
      <Container>
        <h1 className="h3">Königsschießen 2011</h1>
        <hr className="featurette-divider" />
        <p>
          Eine schon traditionelle Veranstaltung des Schützenvereins Gambach ist
          sein alljährliches Sommerfest mit dem Königsschießen. Bereits zum 16.
          Male und natürlich auch im Jubiläumsjahr, fanden sich wieder viele
          Schützenschwestern und -brüder im Schützenhaus Gambach zu dieser
          Veranstaltung ein. Der Vorsitzende Friedhelm Schwenz konnte neben den
          erschienenen Mitgliedern auch einige Gäste begrüßen und bedankte sich
          bei dem Vorbereitungsteam für die Organisation sowie den Spendern der
          Kuchen und Salate.
        </p>
        <p>
          Am frühen Nachmittag traten die Schützinnen und Schützen des Vereins
          zum diesjährigen Königsschießen an. Mit dem Luftgewehr wurde aus einer
          Entfernung von 10m auf die hölzernen, von Michael Rheinfrank
          angefertigten Königsadler, geschossen. Nachdem das »Zepter« und der
          »Apfel« des Adlers gefallen und damit die »Ritter« und »Edelfräulein«
          ermittelt waren, ließ man sich die verschiedenen selbstgebackenen
          Kuchen zum Kaffee schmecken.
        </p>
        <p>
          Anschließend wurde der Wettkampf fortgesetzt, um die neuen
          »Majestäten« zu ermitteln. Nach knapp zweieinhalbstündigem Wettkampf
          standen die Würdenträger für das Jahr 2011 fest. In diesem Jahr wurde
          Ulrike Beppler-Alt Schützenkönigin, Lothar Döring errang den Titel des
          Schützenkönig - sie erhielten die silbernen Königsketten mit dem
          aktuellen Kettenanhängsel. 1. Edelfräulein wurde Katharina Beppler-Alt
          und 1. Ritter Oswald Wagner; als 2. Edelfräulein sowie 2. Ritter
          vervollständigten Anna-Dorothea Becker und Eberhard Beppler-Alt die
          Königsfamilie. Nach dem Wettkampf stärkten sich die Schützen mit
          gegrillten Köstlichkeiten, die wie immer von Rigo Schubach
          hervorragend zubereitet wurden. Die Schützenfamilie saß anschließend
          in gemütlicher Runde und ließ das Sommerfest erst in den späten
          Abendstunden ausklingen.
        </p>

        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Nach dem Wettkampf präsentierte sich die neue Gambacher
              »Königsfamilie«: Eberhard Beppler-Alt (2. Ritter), Anna-Dorothea
              Becker (2. Edelfräulein), Ulrike Beppler-Alt (Schützenkönigin),
              Lothar Döring (Schützenkönig), Katharina Beppler-Alt (1.
              Edelfräulein) und Oswald Wagner (1. Ritter) – Foto: fs
            </small>
          </Col>
        </Row>

        <p></p>

        <p className="font-weight-bold">
          Anlässlich des Sommerfestes wurden die Kreismeister 2011 des Vereins
          geehrt:
        </p>

        <ul>
          {ratings.map(rating => (
            <li>{rating}</li>
          ))}
        </ul>

        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image02} fluid={true} className="d-block mx-auto" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Koenigsschiessen2011
